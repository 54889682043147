import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import SEO from '../components/SEO'
import {
  HomePage,
} from '../components/StyledComponents'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Footer from '../components/Footer'
import HomeSlider from '../components/Blocks/Slider'
import IconRow from '../components/Blocks/IconRow'
import ImageCta from '../components/Blocks/ImageCta'
import SpeechBubbleBackground from '../assets/images/cta-backgrounds/BloodPressure.jpg'
import CtaBackground from '../assets/images/cta-backgrounds/Discussion.jpg'
import IconCustom from '../components/Base/Icon/Custom'
import Title from '../components/Base/Title'
import Section from '../components/Base/Section'
import SpeechBubbleCta from '../components/Blocks/SpeechBubbleCta'
import SimpleCta from '../components/Blocks/SimpleCta'
import BottomOverlay from '../components/Blocks/BottomOverlay'

export default () => (
  <Layout>
    <SEO
      title='Patient Intake Software'
      description={`Your job just got easier. Introducing your personal electronic medical record assistant.`}
    />
    <HomePage>
      <Header />
      <HomeSlider />
      <IconRow
        columns={[
          {
            id: 'secure',
            colIcon: 'first',
            colNum: 'First',
            colDesc: 'While in the waiting or exam rooms, patients use our mobile app to answer questions pertinent to their visit posed by ChartMedic\'s intuitive patient intake software.',
          },
          {
            id: 'providers',
            colIcon: 'second',
            colNum: 'Second',
            colDesc: 'Clinicians view clinical information produced by ChartMedic from patients\' answers, so they can focus their interactions with patients more on addressing the patients\' concerns rather than on discovering them.',
          },
          {
            id: 'chart',
            colIcon: 'third',
            colNum: 'Third',
            colDesc:
              'Staff import draft chart documentation produced between the first two steps into the electronic medical record with a couple of clicks. No typing is required.',
          },
        ]}
      />
      <SimpleCta
        link='/how-it-works'
        linkTitle='Learn About How it Works'
      />
      <SpeechBubbleCta
        id='saveTime'
        background={SpeechBubbleBackground}
        short
        icon={<IconCustom theme='time' />}
        bubbleone='Gathering clinical information during the patient intake process lets patients and providers get back to talking.'
        bubbletwo='Time to really listen to patients enhances the quality of care, thus improving the patient experience.'
        description='Having intake software that gathers clinical information ahead of time with ChartMedic frees up 4 - 6 minutes of interaction time during each appointment.'
      />
      <Section theme='short' center>
        <ScrollAnimation animateIn='fadeInUp' animateOnce>
          <IconCustom theme='talkcheck' margined />
        </ScrollAnimation>
        <Title level={3}>
          Providers have time to gather patient insights without worrying about missing any important information.
        </Title>
      </Section>
      <ImageCta
        background={CtaBackground}
        icon={<IconCustom theme='target' />}
        title='And having the right information can help improve the accuracy of each medical diagnosis.'
        description='Reducing the documentation burden streamlines clinician workflow and enhances provider productivity.'
        description2='Improve provider efficiency, enhance patient satisfaction, and provide your best care.'
        buttontext='Learn About How it Works'
        buttonlink='/how-it-works'
      />
    </HomePage>
    <Footer />
    <BottomOverlay showCookieCta={true} />
  </Layout>
)
