import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import styled from 'styled-components'
import {
  ButtonLink,
} from '../../StyledComponents'
import FONTS from '../../../constants/fonts'
import COLORS from '../../../constants/colors'
// Image CTA Components
const ImageWrapper = styled.div`
  background-color: ${COLORS.GREYLIGHT};
  background-size: cover;
  background-repeat: no-repeat;
  padding: 8rem 2rem;
  position: relative;
  text-align: center;
`
const CtaPrimary = styled.div`
  max-width: 700px;
  margin: auto;
`
const CtaTitle = styled.h3`
  color: ${COLORS.BLUE};
  font-size: ${FONTS.TITLETHREE};
  font-weight: ${FONTS.WEIGHTMEDIUM};
  max-width: 72rem;
  margin-bottom: 10rem;
`
const CtaIcon = styled.div`
  display: flex;
  justify-content: center;
`
const CtaDesc = styled.div`
  font-size: ${FONTS.FONTSUBTITLE};
  margin: auto;
  max-width: 72rem;
`
const CtaButton = styled.div`
  margin: 8rem 0;
`

// End Image CTA Components
const ImageCta = props => (
  <ScrollAnimation animateIn='fadeIn' animateOnce>
    <ImageWrapper
      id={props.id}
      style={{
        backgroundImage: `url(${props.background})`,
      }}
    >
      <CtaPrimary>
        <CtaIcon>{props.icon}</CtaIcon>
        <CtaTitle>{props.title}</CtaTitle>
      </CtaPrimary>
      <CtaDesc>{props.description}</CtaDesc>
      <p></p>
      <CtaDesc>{props.description2}</CtaDesc>
      <CtaButton>
        <ButtonLink to={props.buttonlink}>{props.buttontext}</ButtonLink>
      </CtaButton>
    </ImageWrapper>
  </ScrollAnimation>
)

export default ImageCta
