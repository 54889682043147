import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import Title from '../../Base/Title'
import {
  ImageWrapper,
  CtaPrimary,
  BubbleWrap,
  BubbleOne,
  BubbleTwo,
  CtaIcon,
  CtaDesc,
} from './styles'

// End Image CTA Components
const SpeechBubbleCta = props => (
  <ScrollAnimation animateIn='fadeIn' animateOnce>
    <ImageWrapper
      id={props.id}
      style={{
        backgroundImage: `url(${props.background})`,
      }}
    >
      <CtaPrimary>
        <BubbleWrap>
          <BubbleOne>{props.bubbleone}</BubbleOne>
          <BubbleTwo>{props.bubbletwo}</BubbleTwo>
        </BubbleWrap>
        <CtaIcon>{props.icon}</CtaIcon>
        <CtaDesc>
          <Title level={3}>{props.description}</Title>
        </CtaDesc>
      </CtaPrimary>
    </ImageWrapper>
  </ScrollAnimation>
)

export default SpeechBubbleCta
