import styled from 'styled-components'

import fonts from '../../../constants/fonts'
import media from '../../../constants/media'
import colors from '../../../constants/colors'
// Image CTA Components
export const ImageWrapper = styled.div`
  background-color: ${colors.GREYLIGHT};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  padding: 6rem 2rem;
  ${media.small`
    background-position: center center;
    text-align: center;
  `}
`
export const CtaPrimary = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ${media.small`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  `}
`
export const CtaTitle = styled.h2`
  font-size: ${fonts.FONTSUBTITLE};
  font-weight: ${fonts.WEIGHTREGULAR};
  padding: 20px;
  max-width: 640px;
`
export const BubbleWrap = styled.div`
  font-weight: ${fonts.WEIGHTREGULAR};
  padding: 2rem;
  ${media.medium`
    max-width: 80rem;
    display: flex;
    align-items: flex-start;
  `}
`
export const BubbleOne = styled.div`
  background-color: ${colors.BLUE};
  border-radius: 2rem;
  color: ${colors.WHITE};
  font-size: ${fonts.TITLETHREE};
  font-weight: ${fonts.WEIGHTREGULAR};
  padding: 3rem 2rem 4rem;
  margin-bottom: 6rem;
  max-width: 64rem;
  position: relative;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 4rem 4rem 0;
    border-color: transparent ${colors.BLUE} transparent transparent;
    position: absolute;
    bottom: -4rem;
    right: 4rem;
  }
  ${media.medium`
    margin-right: 2rem;
  `}
`
export const BubbleTwo = styled.div`
  background-color: ${colors.WHITE};
  border-radius: 2rem;
  color: ${colors.BLUE};
  font-size: ${fonts.TITLETHREE};
  font-weight: ${fonts.WEIGHTREGULAR};
  padding: 3rem 2rem 4rem;
  max-width: 64rem;
  margin-bottom: 4rem;
  position: relative;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4rem 4rem 0 0;
    border-color: ${colors.WHITE} transparent transparent transparent;
    position: absolute;
    bottom: -4rem;
    left: 4rem;
  }
  ${media.medium`
    margin-top: 8rem;
  `}
`
export const CtaIcon = styled.div`
  display: flex;
  justify-content: center;
`
export const CtaDesc = styled.div`
  margin: 6rem auto;
  max-width: 72rem;
`
