import React from 'react'
import Slider from 'react-slick'
import {
  StyledSlider,
} from './styles'

const HomeSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    swipeToSlide: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  }
  return (
    <StyledSlider>
      <Slider {...settings}>
        <div className='slide-wrap'>
          <h1 className='slide-text'>
            Patient Intake Software
          </h1>
          <div className='slide001' />
        </div>
        <div className='slide-wrap'>
          <div className='slide-text'>
            Restoring Valuable Time to Listen
          </div>
          <div className='slide003' />
        </div>
        <div className='slide-wrap'>
          <div className='slide-text'>
            Simplifying the Practice of Medicine
          </div>
          <div className='slide004' />
        </div>
      </Slider>
    </StyledSlider>
  )
}

export default HomeSlider
